<template>
  <div>
    <div v-if="isacademicsdetailsfreezed == false" cols="12" sm="2" align="center">
      <span class="blink" style="font-size:16px;font-weight:bold;color:red;">You have not yet Freezed the Academics
        Details,Kindly freeze it.(If you have already done it before , Please do it once again)</span>
    </div>
    <div v-else>
      <span style="font-size:16px;font-weight:bold;color:green;"> Academics Details Freezed.</span>
    </div>

    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar dense class="tc-title">
      <router-link to="student-addressDetals" class="nav-arrow-left"><v-icon
          class="left-arrow">mdi-arrow-left-bold</v-icon></router-link>
      <v-toolbar-title>Academics Details </v-toolbar-title>
      <v-spacer></v-spacer>
      <template>
        <span v-if="iserpapplicable == true">
          <v-btn color="primary" class="logi mx-2" small dark @click="synchvierp()">Fetch Academic Details From
            ERP</v-btn>
        </span>
        <v-btn color="primary" class="logi mx-2" fab small dark
          @click="dialog_add = true"><v-icon>mdi-plus</v-icon></v-btn>
        <router-link to="student-internship-project" class="nav-arrow-right"><v-icon
            class="right-arrow">mdi-forward</v-icon></router-link>
        <a class="blink" href="https://www.youtube.com/watch?v=mtv4QY7f9JE" target="_blank"><span
            style="color:red;font-size:35px;" class="mdi mdi-message-video"></span></a>

      </template>
    </v-toolbar>
    <v-row class="mt-5 ml-2 mr-2" cols="12" sm="12">
      <v-card>
        <v-card-text>
          <v-row>
          <v-col cols="3" sm="3">
              <label>Highest Qualification<span class="required">*</span> :</label>
              <v-text-field height="58px"  v-model="selected_highestqua" :items="high_qua_list"  
                  item-text="degree_name"
                  item-value="degree_id" outlined disabled
                  dense ></v-text-field>
          </v-col>
          <v-col cols="3" sm="3">
              <label>SSC Percentage<span class="required">*</span> :</label>
            <v-text-field type="number" :rules="applyRules" :value="0" min="0" placeholder="Enter SSC Percentage" v-model="ssc_cpi_marks" outlined disabled></v-text-field>
          </v-col>

        <v-col cols="3" sm="3">
              <label>HSC Percentage<span class="required">*</span> :</label>
            <v-text-field type="number" :rules="applyRules" :value="0" min="0" placeholder="Enter HSC Percentage" v-model="hsc_cpi_marks" outlined disabled></v-text-field>
          </v-col>
          <v-col cols="3" sm="3">
              <label>Diploma Percentage<span class="required">*</span> :</label>
            <v-text-field type="number" :rules="applyRules" :value="0" min="0" placeholder="Enter HSC Percentage" v-model="diploma_marks" outlined disabled></v-text-field>
          </v-col>

        <v-col cols="3" sm="3">
            <label>Graduation Latest CPI<span class="required">*</span> :</label>
          <v-text-field type="number" :rules="applyRules" :value="0" min="0" placeholder="Enter Graduation Latest CPI" v-model="graduation_cpi_marks" outlined disabled></v-text-field>
        </v-col>

        <v-col cols="3" sm="3">
            <label>Post Graduation CPI<span class="required"></span> :</label>
            <v-text-field type="number" :value="0" min="0" placeholder="Enter Post Graduation CPI" v-model="postgraduation_cpi_marks" outlined disabled></v-text-field>
        </v-col>
        <v-col cols="12" sm="5">
          <label>Please select your admission 12th / Diploma<span style="color: red;">*</span></label>
          <v-row>
          <v-checkbox class="mr-8 ml-3" label="12th" @change="onChangeCheckForHSC(isappearedfor_hsc)" color="primary" v-model="isappearedfor_hsc"></v-checkbox>
          <v-checkbox label="Diploma" @change="onChangeCheckForDiploma(isappearedfor_diploma)" color="primary" v-model="isappearedfor_diploma"></v-checkbox>
          </v-row>
        </v-col>
        <v-col cols="3" sm="3" v-for="(item, index) in learner_list" :key="index">
          <v-switch v-model="item.is_directsecondyearadmission" @change="isDirectswitch(item)"></v-switch>Is Direct Second Year admission?
        </v-col>
        <v-col cols="3" sm="3" v-for="(item, index) in learner_isgoingforhigherstudies_list" :key="index">
          <v-switch v-model="item.isgoingforhigherstudies" @change="higherStudyswitch(item)"></v-switch>Are you going for higher studies?
        </v-col>
        <v-col cols="3" sm="3" v-for="(item, index) in learner_is_interested_in_only_internship_list" :key="index">
          <v-switch v-model="item.is_interested_in_only_internship" @change="isInternswitch(item)"></v-switch>Are you Interested only in Internship?
        </v-col>
       
      </v-row>
        </v-card-text>
      </v-card>
    </v-row>
    <div v-if="academics_list">
      <v-row>
        <v-col cols="12" sm="4" v-for="item in academics_list" :key="item.id">
          <v-card style=" margin: 8px; height:100%">
            <v-card-title class="c-title">
              {{ item.degree }}
            </v-card-title>
            <v-card-text>
              <v-row style="margin: 0px !important; padding: 0px !important">
                <v-col cols="6" sm="5" style="margin: 0px !important; padding: 0px !important">
                  <span class="lbl">Education</span>
                </v-col>
                <v-col cols="6" md="4" style="margin: 0px !important; padding: 0px !important"> : {{ item.degree }}
                </v-col>
              </v-row>
              <v-row style="margin: 0px !important; padding: 0px !important">
                <v-col cols="6" sm="5" style="margin: 0px !important; padding: 0px !important">
                  <span class="lbl">University</span>
                </v-col>
                <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important"> : {{ item.university }}
                </v-col>
              </v-row>
              <v-row style="margin: 0px !important; padding: 0px !important">
                <v-col cols="6" sm="5" style="margin: 0px !important; padding: 0px !important">
                  <span class="lbl">Institute Name</span>
                </v-col>
                <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important"> : {{
                  item.name_of_institute }} </v-col>
              </v-row>
              <v-row style="margin: 0px !important; padding: 0px !important">
                <v-col cols="6" sm="5" style="margin: 0px !important; padding: 0px !important">
                  <span class="lbl">{{ item.type }}</span>
                </v-col>
                <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important"> : {{ item.cpi_marks }}
                </v-col>
              </v-row>
              <!--  <v-row style="margin: 0px !important; padding: 0px !important">
              <v-col cols="6" sm="5" style="margin: 0px !important; padding: 0px !important">
                <span class="lbl">Class</span>
              </v-col>
              <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important"> : {{ item.resultclass }} </v-col>
            </v-row>-->
              <v-row style="margin: 0px !important; padding: 0px !important">
                <v-col cols="6" sm="5" style="margin: 0px !important; padding: 0px !important">
                  <span class="lbl">Passing Year</span>
                </v-col>
                <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important"> : {{ item.yearofpassing }}
                </v-col>
              </v-row>
              <v-row style="margin: 0px !important; padding: 0px !important">
                <v-col cols="6" sm="5" style="margin: 0px !important; padding: 0px !important">
                  <span class="lbl">Highest Qualification</span>
                </v-col>
                <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important"> : {{
                  item.ishighest_qualification_display }} </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-icon v-if="isacademicsdetailsfreezed == false" color="indigo"
                @click="editItem(item)">mdi-pencil-circle</v-icon>
              <v-spacer></v-spacer>
              <v-icon v-if="isacademicsdetailsfreezed == false" color="red"
                @click="delId(item)">mdi-delete-circle</v-icon>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <template>
        <v-row justify="center">
          <v-col cols="12" sm="1" align="center"></v-col>
          <v-col v-if="isacademicsdetailsfreezed == false" cols="12" sm="4" align="center"><br>

            <p class="blink" style="font-size:20px;font-weight:bold;"> NOTE : Please Enter SSC,HSC/Diploma & Graduation
              Marks. </p>
            <!-- <span style="font-size:16px;font-weight:bold;">NOTE : Please Enter SSC,HSC/Diploma Marks.</span><br> -->
            <span style="font-size:18px;font-weight:bold;">NOTE : Freeze the details.</span><br>
            <v-btn style="margin-top:20px" color="primary darken-1" dark @click="freezacademics()">Freeze All</v-btn>
          </v-col>
          <v-col v-else cols="12" sm="4" align="center"><br>
            <span style="font-size:16px;font-weight:bold;color:red;">You Have Already Freezed the Details.</span>
            <v-btn style="margin-top:20px" color="primary darken-1" dark
              @click="unfreezeStudentdetails('academics')">Unfreeze</v-btn>
          </v-col>
        </v-row>
      </template>


    </div>
    <h1 v-else>NO DATA</h1>


    <!--<template>
            <v-toolbar flat dense color="white">
                 <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details>
                    </v-text-field>
                <v-spacer></v-spacer>
                <span style="width: 20px;"></span>
                <template>
                    <v-btn color="primary" dark class="mb-2" @click="dialog_add=true"><v-icon>mdi-plus</v-icon> Academic</v-btn>
                </template>
            </v-toolbar>
        </template>
        <template>
            <v-data-table :headers="headers" :items="academics_list" class="elevation-1" :search="search">
                <template v-slot:item.edit="{ item }">
                         <v-icon color="indigo" @click="editItem(item)">mdi-pencil-circle</v-icon>
                </template>
               <template v-slot:item.delete="{ item }">
                    <v-icon color="red" @click="delId(item)">mdi-delete-circle</v-icon>
                </template>
            </v-data-table>
        </template>-->
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog_add" persistent max-width="800px">
          <v-card>
            <v-card-title class="card-title"> Add Academic Details</v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6">
                    <label>Education<span class="required" :rules="[v => !!v || 'required']">*</span> :</label>
                    <v-select :items="degree_List" v-model="savedata.degree" item-text="degree" item-value="id"
                      @change="changefieldname" :rules="[v => !!v || 'required']"></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <label>Name of Qualification<span class="required">*</span>
                      :</label>
                    <v-text-field placeholder="Enter Name of Qualification" v-model="savedata.degree_name"
                      density="compact" :rules="[ruless, rules.isEmpty]"
                      onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode== 40 || event.charCode== 41 || event.charCode == 32"
                      :error-messages="error.savedata.degree_name ? 'Please Enter Valid Qualification' : ''"></v-text-field>


                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <label>Board/University Name<span class="required" :rules="[v => !!v || 'required']">*</span>
                      :</label>
                    <v-select :items="uni_list" v-model="savedata.uni" item-text="uni" item-value="id"
                      @change="changefieldnameuni" :rules="[v => !!v || 'required']"></v-select>
                    <span v-if="isother == true">
                      <v-text-field placeholder="Enter Board/University Name"
                        v-model="savedata.newUniversity"></v-text-field>
                    </span>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <label>Institute Name<span class="required" :rules="[v => !!v || 'required']">*</span> :</label>
                    <v-text-field placeholder="Enter Institute Name" v-model="savedata.institue_name"
                    density="compact" :rules="[ruless, rules.isEmpty]"
                      onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode== 40 || event.charCode== 41 || event.charCode == 32"
                      :error-messages="error.savedata.institue_name ? 'Please Enter Valid Institute name' : ''"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col v-if="cpifieldname == 'CPI'" cols="12" sm="6">
                    <label>CPI<span class="required" :rules="[v => !!v || 'required']">*</span> :</label>
                    <v-text-field :rules="[v => !!v || 'required']" :placeholder="cpifieldname" type="number" v-model="savedata.percentage_cpi" 
                      hint="Only Enter Number Value" :value="0" min="0"></v-text-field>
                  </v-col>
                  <v-col v-else cols="12" sm="6">
                    <label>Percentage<span class="required" :rules="[v => !!v || 'required']">*</span> :</label>
                    <v-text-field :rules="[v => !!v || 'required']" :placeholder="cpifieldname" type="number" v-model="savedata.percentage_cpi"
                      hint="Only Enter Number Value" :value="0" min="0"></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" sm="6">
                    <label>Class Obtained<span class="required" :rules="[v => !!v || 'required']">*</span> :</label>
                    <v-select
                      :items="class_List"
                      v-model="savedata.pass_class"
                      item-text="resultClass"
                      item-value="id"
                      :rules="[v => !!v || 'required']" 
                    ></v-select>
                  </v-col>-->
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4">
                    <label>Passing Year<span class="required" :rules="[v => !!v || 'required']">*</span> :</label>
                    <v-text-field placeholder="Enter Passing Year" type="number" hint="Ex. 2022,2023"
                      v-model="savedata.pass_year" :rules="[v => !!v || 'required']" :value="0" min="0"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <label>Is Degree Completed?<span class="required" :rules="[v => !!v || 'required']">*</span> :</label>
                    <v-select :items="iscompleted_list" v-model="savedata.is_compl" item-text="name" item-value="id"
                      :rules="[v => !!v || 'required']"></v-select>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <h4><input type="checkbox"
                      v-model="savedata.ishighest_qualification"
                      color="success" style="margin: 0.4rem; width:18px; height: 15px;"
                      />&nbsp;&nbsp;Is Highest Qualification?</h4>
                  </v-col>
                </v-row>
                <v-row>
                  <h4><input type="checkbox"
                    v-model="savedata.isackowledge"
                      color="success" style="margin: 0.4rem; width:18px; height: 15px;"
                      />&nbsp;&nbsp;I acknowledge, this data is correct as per original marksheet, if found incorrect college reserves all rights to ban me from TPO.</h4>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- <v-btn color="blue darken-1" text @click="dialog_add = false"
                >Close</v-btn
              >
              <v-btn color="blue darken-1" text @click="save()">Save</v-btn> -->
              <v-btn color="primary darken-1" outlined text @click="dialog_add = false">Cancel</v-btn>
              <v-btn color="primary darken-1" dark @click="save()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog_edit" persistent max-width="800px">
          <v-card>
            <v-card-title class="card-title"> Edit Academic Details</v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6">
                    <label>Education<span class="required">*</span> :</label>
                    <v-select :items="degree_List" v-model="degree1.id" item-text="degree" item-value="id"
                      @change="changefieldnameEdit" required></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <label>Name of Qulification<span class="required">*</span> :</label>
                    <v-text-field placeholder="Enter Name of Qulification"
                      v-model="editedItem.degree_name"
                      density="compact" :rules="[ruless, rules.isEmpty]"
                      onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode== 40 || event.charCode== 41 ||  event.charCode == 32"
                      :error-messages="error.editedItem.degree_name ? 'Please Enter Valid Qualification' : ''"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <label>Board/University Name<span class="required">*</span> :</label>
                    <v-select :items="uni_list" v-model="uni1.id" item-text="uni" item-value="id"
                      @change="changefieldnameuniEdit" required></v-select>

                    <span v-if="isother == true">
                      <v-text-field placeholder="Enter Board/University Name"
                        v-model="editedItem.editnewUniversity"></v-text-field>
                    </span>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <label>Institue Name<span class="required">*</span> :</label>
                    <v-text-field placeholder="Enter Institue Name" v-model="editedItem.institue_name"
                    density="compact" :rules="[ruless, rules.isEmpty]"
                      onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode== 40 || event.charCode== 41 || event.charCode == 32"
                      :error-messages="error.editedItem.institue_name ? 'Please Enter Valid Qualification' : ''"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <label>{{ cpifieldname }}<span class="required">*</span> :</label>
                    <v-text-field :rules="[v => !!v || 'required']" :placeholder="cpifieldname" type="number" v-model="editedItem.percentage_cpi"
                      hint="Only Enter Number Value" :value="0" min="0"></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" sm="6">
                    <label>Class Obtained<span class="required">*</span> :</label>
                    <v-select
                      :items="class_List"
                      v-model="passing_class.id"
                      item-text="resultClass"
                      item-value="id"
                      required
                    ></v-select>
                  </v-col> -->
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4">
                    <label>Passing Year<span class="required">*</span> :</label>
                    <v-text-field :rules="[v => !!v || 'required']" type="number" placeholder="Enter Passing Year" v-model="editedItem.pass_year"
                      hint="Ex. 2022,2023" :value="0" min="0"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <label>Is Degree Completed?<span class="required">*</span> :</label>
                    <v-select :rules="[v => !!v || 'required']" :items="iscompleted_list" v-model="is_compl.id" item-text="name" item-value="id"
                      required></v-select>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <h4><input type="checkbox"
                      v-model="editedItem.ishighest_qualification"
                      color="success" style="margin: 0.4rem; width:18px; height: 15px;"
                      />&nbsp;&nbsp;Is Highest Qualification?</h4>
                  </v-col>
                </v-row>
                <v-row>
                  <h4><input type="checkbox"
                      v-model="editedItem.isackowledge"
                      color="success" style="margin: 0.4rem;"
                      />&nbsp;&nbsp;I acknowledge, this data is correct as per original marksheet, if found incorrect college reserves all rights to ban me from TPO.</h4>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- <v-btn color="blue darken-1" text @click="dialog_edit = false"
                >Close</v-btn
              >
              <v-btn color="blue darken-1" text @click="edit()">Update</v-btn> -->
              <v-btn color="primary darken-1" outlined text @click="dialog_edit = false">Cancel</v-btn>
              <v-btn color="primary darken-1" dark @click="edit()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog_delete" persistent max-width="290">
          <v-card>
            <v-card-title class="del-card-title">Record Delete</v-card-title>
            <input type="hidden" v-model="deleteItem.academic_id" />
            <v-card-text>
              Are you sure you want Delete this Record?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dialog_delete = false">No</v-btn>
              <v-btn color="red darken-1" text @click="deletedata()">Yes</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <br>
    <!--<template>
            <v-toolbar dense  class="tc-title">
               <v-toolbar-title>Employability Details</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <span style="width: 20px;"></span>
                <template>
                    <v-btn color="primary" dark  class="logi"  @click="dialog_add_empb=true"><v-icon>mdi-plus</v-icon> Add Record</v-btn>
                </template>
            </v-toolbar>
        </template>
        <template>
            <v-data-table :headers="headersnew" :items="academics_list" class="elevation-1" :search="search"> -->
    <!--<v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">Sr. No.</th>
                            <th class="text-left">Academic Year</th>
                            <th class="text-left">Semester</th>
                            <th class="text-left">Test</th>
                            <th class="text-left">Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in score_list" :key="item">
                            <td>{{ item.srno }}</td>
                            <td>{{ item.ay }}</td>
                            <td>{{ item.sem }}</td>
                            <td>{{ item.test_name }}</td>
                            <td>{{ item.score }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-row>
            <v-col cols="12" sm='4' v-for="item in score_list" :key="item">
                <v-card>
                    <v-card-title class="ec-title">
                           {{item.test_name}}
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6" sm="4">
                                <span class="lbl">Academic Year</span>
                            </v-col>
                            <v-col cols="6" md="6">
                                : {{item.ay}}
                            </v-col>
                        </v-row>
                        <v-row>    
                            <v-col cols="6" sm="4">
                                <span class="lbl">Semester</span>
                            </v-col>
                            <v-col cols="6" md="6">
                                : {{item.sem}}
                            </v-col>
                        </v-row>
                        <v-row>    
                            <v-col cols="6" sm="4">
                                <span class="lbl">Score</span>
                            </v-col>
                            <v-col cols="6" md="6">
                                : {{item.score}}
                            </v-col>
                        </v-row> 
                    </v-card-text>
                </v-card>
            </v-col>
         </v-row>
        </template>
        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog_add_empb" persistent max-width="550px">
                    <v-card>
                        <v-card-title class="card-title">
                            Add Employability Details
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-select :items="ay_List" label="Academic Year" v-model="empb_savedata.ay" item-text="ay" item-value="id" required></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-select :items="sem_List" label="Semester" v-model="empb_savedata.sem" item-text="sem" item-value="id" required></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-select :items="test_List" label="Test" v-model="empb_savedata.test" item-text="test" item-value="id" required></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Marks" type="number" v-model="empb_savedata.score"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog_add_empb= false">Close</v-btn>
                            <v-btn color="blue darken-1" text @click="testsave()">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>-->
  </div>
</template>
<script>
import axios from "axios";
import StudentProfile from "./StudentProfile";
export default {
  components: {
    StudentProfile,
  },
  data() {

    return {

      ruless: [
        (value) => {
          if (!value) {
            return [];
          } else if (/^\d+$/.test(value)) {
            return "Numeric values are not allowed";
          } else {
            return true;
          }
        },
      ],
      rules: {
        isEmpty: (value) => !!value || 'Field is required',
      },
      cpiRules: [
        v => v <= 10 || 'Enter value between 1-10',
      ],
      percentageRules: [
        v => v <= 100 || 'Enter value between 1-100',
      ],

      dialog_add_empb: false,
      empb_savedata: {
        ay: null,
        sem: null,
        test: null,
        score: null,
      },
      savedata: {
        ishighest_qualification: null,
        degree: null,
        degree_name: null,
        //university_name: null,
        institue_name: null,
        percentage_cpi: null,
        pass_class: null,
        pass_year: null,
        is_compl: null,
        newUniversity: "",
      },
      error: {
        savedata: {
          ishighest_qualification: false,
          degree: false,
          degree_name: false,
          //university_name: null,
          institue_name: false,
          percentage_cpi: false,
          pass_class: false,
          pass_year: false,
          is_compl: false,
          newUniversity: false,
        },
        editedItem: {
          ishighest_qualification: false,
          degree: false,
          degree_name: false,
          //university_name: null,
          institue_name: false,
          percentage_cpi: false,
          pass_class: false,
          pass_year: false,
          is_compl: false,
          newUniversity: false,
        },
      },
      isother: false,
      dialog_add: false,
      dialog_edit: false,
      finalDegreeList: [],
      cpifieldname: "Percentage/CPI",
      dialog_delete: false,
      snackbar_msg: "",
      color: "",
      snackbar: false,
      search: "",
      academics_list: null,
      score_list: null,
      degree_List: "",
      uni_list: "",
      class_List: "",
      iserpapplicable: "",
      iscompleted_list: [
        { id: "true", name: "Yes" },
        { id: "false", name: "No" },
      ],
      headers: [
        {
          text: "Sr. No",
          align: "left",
          sortable: false,
          value: "srno",
        },
        {
          text: "Education",
          align: "left",
          sortable: false,
          value: "degree",
        },
        {
          text: "Name of Qulification",
          align: "left",
          sortable: false,
          value: "degree_name",
        },
        {
          text: "University",
          align: "left",
          sortable: false,
          value: "uni",
        },
        {
          text: "Institute Name",
          align: "left",
          sortable: false,
          value: "name_of_institute ",
        },
        {
          text: "Percentage/CPI",
          align: "left",
          sortable: false,
          value: "cpi_marks",
        },
        {
          text: "Class",
          align: "left",
          sortable: false,
          value: "resultclass",
        },
        {
          text: "Year of Passing",
          align: "left",
          sortable: false,
          value: "yearofpassing",
        },
        {
          text: "Edit",
          value: "edit",
          sortable: false,
        },
        {
          text: "Delete",
          value: "delete",
          sortable: false,
        },
      ],

      headersnew: [
        {
          text: "Sr. No",
          align: "left",
          sortable: false,
          value: "srno",
        },
        {
          text: "Academic Year",
          align: "left",
          sortable: false,
          value: "ay",
        },
        {
          text: "Semester",
          align: "left",
          sortable: false,
          value: "sem",
        },
        {
          text: "Test",
          align: "left",
          sortable: false,
          value: "test_name",
        },
        {
          text: "Score",
          align: "left",
          sortable: false,
          value: "score",
        },
        {
          text: "Highest Qualification",
          align: "left",
          sortable: false,
          value: "ishighest_qualification_display",
        },
      ],

      is_complat: null,
      editedItem: {
        degree_name: null,
        //university_name: null,
        institue_name: null,
        percentage_cpi: null,
        pass_year: null,
        ishighest_qualification: null,
        id: "",
        editnewUniversity: "",
      },
      deleteItem: {
        academic_id: null,
      },
      degree1: {
        id: "",
        degree: "",
      },
      uni1: {
        id: "",
        uni: "",
      },
      passing_class: {
        id: "",
        resultClass: "",
      },
      is_compl: {
        id: "",
        name: "",
      },
      editAllData: [],

      ay_List: null,
      sem_List: null,
      test_List: null,
      body: {
        email: "",
      },
      isdatafetched: false,
      academic_data: [],
      high_qua_list:[],
      postgraduation_cpi_marks: null,
      graduation_cpi_marks: null,
      hsc_cpi_marks: null,
      ssc_cpi_marks: null,
      selected_highestqua: null,
      is_interested_in_only_internship: false,
      isgoingforhigherstudies: false,
      is_directsecondyearadmission: false,
      learner_list: [],
      learner_isgoingforhigherstudies_list: [],
      learner_is_interested_in_only_internship_list:[],
      isappearedfor_hsc: false,
      isappearedfor_diploma: false,
      diploma_marks:"",

    };
  },

  mounted() {
    this.onLoad();
  },
  methods: {
    // filterAlphabets(evt) {
    //   evt = (evt) ? evt : window.event;
    //   let expect = evt.target.value.toString() + evt.key.toString();

    //   if ((/[^a-zA-Z\s]/g, '').test(expect)) {
    //     evt.preventDefault();
    //   } else {
    //     return true;
    //   }

    // },
    filterAlphabets1() {

      this.savedata.degree_name = this.savedata.degree_name.replace(/[^a-zA-Z\s]/g, '');
    },
    onLoad() {
      axios
        .post("/LearnerData/learnerAcademics")
        .then((res) => {
          if (res.data.msg == "200") {
            this.academics_list = res.data.learner_academics;
            this.degree_List = res.data.degree_List;
            this.uni_list = res.data.uni_list;
            this.class_List = res.data.resultClass_List;
            this.finalDegreeList = res.data.finalDegreeList;
            this.ay_List = res.data.ay_List;
            this.sem_List = res.data.sem_List;
            this.test_List = res.data.empb_test_List;
            this.score_list = res.data.empb_test_score_List;
            this.isacademicsdetailsfreezed = res.data.isacademicsdetailsfreezed;
            this.iserpapplicable = res.data.iserpapplicable;
            this.high_qua_list = res.data.high_qua_list;
            this.selected_highestqua = res.data.degree_id;
            this.ssc_cpi_marks = res.data.ssc_cpi_marks;
            this.hsc_cpi_marks = res.data. hsc_cpi_marks;
            this.graduation_cpi_marks = res.data.graduation_cpi_marks;
            this.postgraduation_cpi_marks = res.data.postgraduation_cpi_marks;
            this.is_intertesredinOnlyInternship = res.data.is_intertesredinOnlyInternship;
            this.ishigherstudies = res.data.ishigherstudies;
            this.is_directsecondyear = res.data.is_directsecondyear;
            this.learner_list = res.data.learner_list;
            this.learner_isgoingforhigherstudies_list = res.data.learner_isgoingforhigherstudies_list;
            this.learner_is_interested_in_only_internship_list = res.data.learner_is_interested_in_only_internship_list;
            this.isappearedfor_hsc = res.data.isappearedfor_hsc;
            this.isappearedfor_diploma =res.data.isappearedfor_diploma;
            this.diploma_marks = res.data.diploma_marks;
            //console.log(res.data.isacademicsdetailsfreezed)
          } else {
            this.showSnackbar("#b71c1c", "No Data");
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          //window.console.log(error);
        })
        .finally(() => {
          // 'use strict';
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    freezacademics() {
      if (confirm("Are you sure ?")) {
        axios
          .post("/LearnerData/freezacademics")
          .then((res) => {
            if (res.data.msg == "200") {
              this.dialog_edit = false;
              this.showSnackbar("#4caf50", "Academic Details Freezed Successfully."); // show snackbar on success

              this.isacademicsdetailsfreezed = res.data.isacademicsdetailsfreezed;

            } else if (res.data.msg == "FALSE") {
              // this.editAllData.splice(this.editAllData);
              // this.showSnackbar("#b71c1c", "Record Already Present!!!");
              this.showSnackbar("#b71c1c", res.data.ismarkszeromsg);
            }
          })
          .catch((error) => {
            //window.console.log(error);
          });
      }
    },
    editItem(item) {
      if (this.isacademicsdetailsfreezed == true) {
        this.showSnackbar("#b71c1c", "You have already Freezed the details.");
        return;
      }
      this.dialog_edit = true;
      this.editedItem.degree_name = item.degree_name;
      //this.editedItem.university_name = item.university;
      this.editedItem.institue_name = item.name_of_institute;
      this.editedItem.percentage_cpi = item.cpi_marks;
      this.editedItem.pass_year = item.yearofpassing;
      this.editedItem.id = item.id;
      this.editedItem.ishighest_qualification = item.ishighest_qualification;
      this.degree1.id = item.degree_id;
      this.degree1.degree = item.degree_name;
      this.uni1.id = item.uni_id;
      this.uni1.uni = item.uni_name;
      this.passing_class.id = item.resultclass_id;
      this.passing_class.resultClass = item.resultclass;
      if (item.ispersuing == true) {
        this.is_compl.id = "true";
        this.is_compl.name = "Yes";
      } else {
        this.is_compl.id = "false";
        this.is_compl.name = "No";
      }
      this.changefieldnameEdit();
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    hasWhiteSpace(s) {
      var status = false;
      var reWhiteSpace = new RegExp(/^\s+$/);

      // Check for white space
      if (reWhiteSpace.test(s)) {
        alert("Please Check Your Fields For Spaces");
        //   return true;
        return /^\s/.test(s);
      }
      //   return false;
      return /^\s/.test(s);
    },
    save() {
      if (this.savedata.degree == null) {
        this.showSnackbar("#b71c1c", "Select Education Type...");
      } else if (this.savedata.degree_name == null || /^[ \d]+$/.test(this.savedata.degree_name) || this.hasWhiteSpace(this.savedata.degree_name)) {
        this.error.savedata.degree_name = true;
        this.showSnackbar("#b71c1c", "Enter Qualification Name..");
      }
      // else if (this.savedata.university_name == null) {
      //   this.showSnackbar("#b71c1c", "Select University Name..");
      // } 
      else if (this.isother == true && this.savedata.newUniversity == "" || this.savedata.newUniversity == "null") {
        this.showSnackbar("#b71c1c", "Enter University Name..");
        return
      }
      else if (this.isother == false && this.savedata.uni == "" || this.savedata.uni == null) {
        this.showSnackbar("#b71c1c", "Select University Name..");
        return
      }
      else if (this.savedata.institue_name == null || /^[ \d]+$/.test(this.savedata.institue_name) || this.hasWhiteSpace(this.savedata.institue_name)) {
        this.showSnackbar("#b71c1c", "Enter Institute Name..");
        return
      } else if (this.savedata.percentage_cpi == null) {
        this.showSnackbar("#b71c1c", "Enter Percentage/CPI..");
      } else if (this.savedata.pass_year == null) {
        this.showSnackbar("#b71c1c", "Enter Passing Year..");
      } else if (this.savedata.is_compl == null) {
        this.showSnackbar("#b71c1c", "Please select degree is Completed or not?..");
      } else if (this.savedata.isackowledge == null) {
        this.showSnackbar("#b71c1c", "Please select ackowledgement checkbox");
      } else if (this.savedata.pass_year.length != 4) {
        this.showSnackbar("#b71c1c", "Please enter correct passig year format like : 2022");
      } else {

        this.dialog_add = false;
        axios
          .post("/LearnerData/saveAcademics", this.savedata)
          .then((res) => {
            if (res.data.msg == "200") {
              this.onLoad();
              this.showSnackbar("#4caf50", "Academics Add Successfully..."); // show snackbar on success
              this.savedata.degree = null;
              this.savedata.uni = null;
              this.savedata.degree_name = null;
              //this.savedata.university_name = null;
              this.savedata.institue_name = null;
              this.savedata.percentage_cpi = null;
              this.savedata.pass_class = null;
              this.savedata.pass_year = null;
              this.savedata.is_compl = null;
              this.savedata.isackowledge = null;
              this.savedata.ishighest_qualification = null;
              this.isother = false;
            } else {
              this.showSnackbar("#b71c1c", "Academics Already Present!!!");
              this.savedata.degree = null;
              this.savedata.uni = null;
              this.savedata.degree_name = null;
              this.savedata.university_name = null;
              this.savedata.institue_name = null;
              this.savedata.percentage_cpi = null;
              this.savedata.pass_class = null;
              this.savedata.pass_year = null;
              this.savedata.is_compl = null;
              this.savedata.isackowledge = null;
              this.savedata.ishighest_qualification = null;
              this.isother = false;
            }
          })
          .catch((error) => {
            // window.console.log(error);
          });

        this.close();

      }
    }, // end of Save()

    edit() {
      if (this.isother == true && this.editedItem.editnewUniversity == "" || this.editedItem.editnewUniversity == "null") {
        this.showSnackbar("#b71c1c", "Enter University Name..");
        return
      } else if (this.isother == false && this.uni1.id == "" || this.uni1.id == null) {
        this.showSnackbar("#b71c1c", "Select University Name..");
        return
      }else if(!this.editedItem.degree_name || /^[ \d]+$/.test(this.editedItem.degree_name) || this.hasWhiteSpace(this.editedItem.degree_name)){
        this.showSnackbar("#b71c1c", "Enter Valid Qualification Name..");
        return
      }else if(!this.editedItem.institue_name || /^[ \d]+$/.test(this.editedItem.institue_name) || this.hasWhiteSpace(this.editedItem.institue_name)){
        this.showSnackbar("#b71c1c", "Enter Valid Institute Name..");
        return
      }

      if (this.editedItem.isackowledge == null) {
        this.showSnackbar("#b71c1c", "Please check ackowledgement checkbox");
      } else {
        this.dialog_edit = false;
        this.editAllData.push(this.editedItem); //0
        this.editAllData.push(this.degree1);//1
        this.editAllData.push(this.passing_class); //2
        this.editAllData.push(this.is_compl); //3
        this.editAllData.push(this.uni1); //4
        // this.editAllData.push(this.newUniversity);
        axios
          .post("/LearnerData/editAcademics", this.editAllData)
          .then((res) => {
            if (res.data.msg == "200") {
              this.dialog_edit = false;
              this.showSnackbar("#4caf50", "Update Successfully..."); // show snackbar on success
              this.onLoad();
              this.isother = false;
              this.editAllData.splice(this.editAllData);
            } else {
              this.editAllData.splice(this.editAllData);
              this.showSnackbar("#b71c1c", "Record Already Present!!!");
            }
          })
          .catch((error) => {
            //window.console.log(error);
          });
        this.close();
      }
    }, // end of edit()
    delId(item) {
      this.dialog_delete = true;
      this.deleteItem.academic_id = item.id;
    },
    deletedata() {
      this.dialog_delete = false;
      axios
        .post("/LearnerData/deleteAcademics", this.deleteItem)
        .then((res) => {
          if (res.data.msg == "200") {
            if ((this.load = true)) {
              this.onLoad();
              this.showSnackbar("#4caf50", " Record Delete Successfully...");
              th;
            }
          } else if (res.data.msg == "2001") {
            this.showSnackbar("#b71c1c", "Can't Delete Academics, already refered in Learner Profile");
          } else {
            this.showSnackbar("#b71c1c", "Record Not Delete...");
          }
        });
    }, //end,
    testsave() {
      if (this.empb_savedata.ay == null) {
        this.showSnackbar("#b71c1c", "Select Academic...");
      } else if (this.empb_savedata.sem == null) {
        this.showSnackbar("#b71c1c", "Select Semester..");
      } else if (this.empb_savedata.test == null) {
        this.showSnackbar("#b71c1c", "Select Test..");
      } else if (this.empb_savedata.score == null) {
        this.showSnackbar("#b71c1c", "Enter Score..");
      } else {
        this.dialog_add_empb = false;
        axios
          .post("/LearnerData/saveemployability", this.empb_savedata)
          .then((res) => {
            if (res.data.msg == "200") {
              this.onLoad();
              this.showSnackbar("#4caf50", "Emplobility Add Successfully..."); // show snackbar on success
              this.empb_savedata.ay = null;
              this.empb_savedata.test = null;
              this.empb_savedata.score = null;
              this.empb_savedata.sem = null;
            } else {
              this.showSnackbar("#b71c1c", "Record Already Present!!!");
            }
          })
          .catch((error) => {
            // window.console.log(error);
          });

        this.close();
      }
    }, // end of Save()

    synchvierp() {
      this.isLoading = true
      axios
        .post("/AdminImport/syncacdemic")
        .then((res) => {
          if (res.data.status == "200") {
            //window.alert(`DAta--> ${res.data.academic_list}`)
            this.showSnackbar("#4caf50", "Learner Synchronized Successfully..!");
            this.isdatafetched = true;
            //  this.academic_data=this.onLoad();
            //  window.console.log(`Academic DATA ${this.academic_data}`)
            this.isLoading = false;

          } else if (res.data.status.code == "NA") {
            this.showSnackbar("#b71c1c", "Error While Synchronization");
            this.isLoading = false;
          }
          else if (res.data.status == "404") {
            this.showSnackbar("#b71c1c", res.data.msg);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          //window.console.log(error);
          this.isLoading = false;
        });
    },

    unfreezeStudentdetails(item) {
      if (confirm("Are you sure, you want to unfreeze ?")) {
        const data = {
          value: item,
        };
        axios
          .post("/LearnerData/unfreezeStudentdetailsstudentside", data)
          .then((res) => {
            if (res.data.msg == "200") {
              this.onLoad();
              this.showSnackbar("#4caf50", "Details Unfreezed Successfully."); // show snackbar on success



            } else {
              // this.editAllData.splice(this.editAllData);
              // this.showSnackbar("#b71c1c", "Record Already Present!!!");
            }
          })
          .catch((error) => {
            //window.console.log(error);
          });
      }

    },

    changefieldname() {
      for (var i = 0; i < this.finalDegreeList.length; i++) {
        if (this.savedata.degree == this.finalDegreeList[i].id) {
          this.cpifieldname = this.finalDegreeList[i].type;
          break;
        }
      }
    },
    changefieldnameuni() {
      this.isother = false;
      for (var i = 0; i < this.uni_list.length; i++) {
        if (this.savedata.uni == this.uni_list[i].id) {
          if (this.uni_list[i].uni == "Other") {
            this.isother = true;
          }
          break;
        }
      }
    },
    changefieldnameEdit() {
      for (var i = 0; i < this.finalDegreeList.length; i++) {
        if (this.degree1.id == this.finalDegreeList[i].id) {
          this.cpifieldname = this.finalDegreeList[i].type;
          break;
        }
      }
    },
    changefieldnameuniEdit() {
      this.isother = false;
      for (var i = 0; i < this.uni_list.length; i++) {
        if (this.uni1.id == this.uni_list[i].id) {
          if (this.uni_list[i].uni == "Other") {
            this.isother = true;
          }
          break;
        }
      }
    },

    isDirectswitch(item) {
            // console.log("abc");

            const data = {
              id: item.l_id,
              is_directsecondyearadmission: item.is_directsecondyearadmission,
            };
            // console.log(item.id);

            axios
                .post("/LearnerData/isDirectswitch", data)
                .then((res) => {
                // console.log("res.data");
                // console.log(res.data);
                if (res.data.msg == "200") {
                    
                    this.showSnackbar("#4caf50", res.data.message);
                    this.onload();
                } else {
                    
                    this.showSnackbar("#b71c1c", res.data.msg);
                }
                })
                .catch((error) => {
                window.console.log(error);
                });
            this.close();
            },

            higherStudyswitch(item) {
            // console.log("abc");

            const data = {
              id: item.l_id,
              isgoingforhigherstudies: item.isgoingforhigherstudies,
            };
            axios
                .post("/LearnerData/higherStudyswitch", data)
                .then((res) => {
                if (res.data.msg == "200") {
                    this.showSnackbar("#4caf50", res.data.message);
                    this.onload();
                } else {
                    this.showSnackbar("#b71c1c", res.data.msg);
                }
                })
                .catch((error) => {
                window.console.log(error);
                });
            this.close();
            },

            isInternswitch(item) {
            // console.log("abc");

            const data = {
              id: item.l_id,
              is_interested_in_only_internship: item.is_interested_in_only_internship,
            };
            axios
                .post("/LearnerData/isInternswitch", data)
                .then((res) => {
                if (res.data.msg == "200") {
                    this.showSnackbar("#4caf50", res.data.message);
                    this.onload();
                } else {
                    this.showSnackbar("#b71c1c", res.data.msg);
                }
                })
                .catch((error) => {
                window.console.log(error);
                });
            this.close();
            },

            onChangeCheckForHSC(item) {
            const data = {
              isappearedfor_hsc: item,
            };
            axios
                .post("/LearnerData/onChangeCheckForDiploma", data)
                .then((res) => {
                if (res.data.msg == "200") {
                    this.showSnackbar("#4caf50", res.data.message);
                    this.onload();
                } else {
                    this.showSnackbar("#b71c1c", res.data.msg);
                }
                })
                .catch((error) => {
                window.console.log(error);
                });
            this.close();
            },

            onChangeCheckForDiploma(item) {
            const data = {
              isappearedfor_diploma: item,
            };
            axios
                .post("/LearnerData/onChangeCheckForDiploma", data)
                .then((res) => {
                if (res.data.msg == "200") {
                    this.showSnackbar("#4caf50", res.data.message);
                    this.onload();
                } else {
                    this.showSnackbar("#b71c1c", res.data.msg);
                }
                })
                .catch((error) => {
                window.console.log(error);
                });
            this.close();
            },

  },
  watch: {
    isdatafetched() {
      //window.console.log("ISDATAfetch"+this.isdatafetched)
      if (this.isdatafetched == true) {
        this.onLoad();
        this.isdatafetched = false;
      }
    },

  }
};
</script>
<style scoped>
.nav-arrow-left {
  margin-right: 5px;
  text-decoration: none;
  color: #fff;
}

.nav-arrow-right {
  margin: 5px;
  text-decoration: none;
  color: rgb(3, 55, 167);
}

.left-arrow {
  color: #fff;
}

.right-arrow {
  color: rgb(3, 55, 167);
}

.ec-title {
  background-image: linear-gradient(-100deg, #f4faba, #d64907);
  color: #fff;
}

.c-title {
  background-image: linear-gradient(-90deg, #befcf7, #03948d);
  color: #fff;
}

.tc-title {
  background-image: linear-gradient(-90deg, #fff, #057996);
  color: #fff;
  border-radius: 3px;
}

.logi {
  background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
  color: #fff;
}

.card-title {
  background-image: linear-gradient(-90deg, #8edffd, #034c70);
  color: #fff;
}

.del-card-title {
  background-image: linear-gradient(-90deg, #fde7e7, #ac0404);
  color: #fff;
}

.lbl {
  color: #1705e6;
}

.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.alink {
  text-decoration: none;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}

.required {
  color: red;
  font-size: 14px;
  font-style: bold;
}

.blink {
  animation: blinker 0.77s linear infinite;
  color: red;
  font-family: sans-serif;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
